import React, { useContext, useEffect, useState } from 'react';
import ThemeContext from '../ThemeContext';
import { Link, useLocation } from 'react-router-dom';
import { LinkedinLogo } from "@phosphor-icons/react";

import SideDrawer from './SideDrawer';
import logo from '../logo.svg';


const NavBar = () => {
    const { theme, toggleTheme } = useContext(ThemeContext);

    const location = useLocation();
    const [prevScrollPos, setPrevScrollPos] = useState(0);
    const [visible, setVisible] = useState(true);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollPos = window.pageYOffset;
            const isVisible = currentScrollPos <= 60 || currentScrollPos < prevScrollPos;

            setPrevScrollPos(currentScrollPos);
            setVisible(isVisible);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [prevScrollPos]);

    useEffect(() => {
        const scrollTop = () => {
            const app = document.querySelector('.App');
            app.scrollTo({ behavior: 'instant', top: 0 });
        }
        scrollTop();
    }, [location]);

    useEffect(() => {
        const tabs = document.querySelectorAll('.tab');

        tabs.forEach((tab) => {
            if (tab.getAttribute('href') === location.pathname) {
                tab.classList.add('tab-active');
            } else {
                tab.classList.remove('tab-active');
            }
        });
    }, [location]);

    const handleDrawerToggle = () => {
        setOpen(!open);
    };

    return (
        <>
            <SideDrawer open={open} setOpen={setOpen} />
            <div className={`w-full fixed top-0 px-4 sm:px-6 z-10 bg-base-100/90 transform transition-transform ${visible ? 'translate-y-0' : '-translate-y-full'}`}>
                <div className="container mx-auto px-0 sm:px-2 flex items-center justify-between py-4">
                    <div className="flex-1 lg:ml-0 flex-row flex items-center">
                        <button className="btn lg:hidden -ml-2 px-2 btn-ghost rounded-md" onClick={handleDrawerToggle}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-10 w-10">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                            </svg>
                        </button>

                        <Link to="/" className="btn btn-ghost normal-case sm:text-xl text-sm"><img src={logo} className="w-10 h-10 mr-2" alt="logo" />I Am Mobile Friendly</Link>
                    </div>

                    <div role=" tablist" className="tabs tabs-bordered hidden lg:flex">
                        <Link to="/" className="tab tab-bordered tab-active">
                            Home
                        </Link>
                        <Link to="/budget-web-development" className="tab">
                            Websites
                        </Link>
                        <Link to="/custom-web-development" className="tab">
                            Web-apps
                        </Link>
                        <Link to="https://www.coachautomationdone4you.com/" target='_blank' className="tab">
                            Lead Automation
                        </Link>
                        <Link to="https://www.linkedin.com/in/rp42/" target='_blank' className="tab">
                            About Me &nbsp;
                            <span className="inline-block text-primary">
                                <LinkedinLogo size={24} />
                            </span>
                        </Link>
                    </div>
                    <div className="hidden lg:flex me-6">
                        <a href="#contact" className="ml-6 btn btn-outline btn-primary text-primary hover:text-white rounded-lg py-0" >
                            Get started
                        </a>
                    </div>

                    <div className="">
                        <label className="swap swap-rotate">
                            <label hidden>Toggle Theme</label>
                            <input type="checkbox" onChange={toggleTheme} checked={theme === 'dark'} />
                            <svg className="swap-on fill-current w-10 h-10" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M5.64,17l-.71.71a1,1,0,0,0,0,1.41,1,1,0,0,0,1.41,0l.71-.71A1,1,0,0,0,5.64,17ZM5,12a1,1,0,0,0-1-1H3a1,1,0,0,0,0,2H4A1,1,0,0,0,5,12Zm7-7a1,1,0,0,0,1-1V3a1,1,0,0,0-2,0V4A1,1,0,0,0,12,5ZM5.64,7.05a1,1,0,0,0,.7.29,1,1,0,0,0,.71-.29,1,1,0,0,0,0-1.41l-.71-.71A1,1,0,0,0,4.93,6.34Zm12,.29a1,1,0,0,0,.7-.29l.71-.71a1,1,0,1,0-1.41-1.41L17,5.64a1,1,0,0,0,0,1.41A1,1,0,0,0,17.66,7.34ZM21,11H20a1,1,0,0,0,0,2h1a1,1,0,0,0,0-2Zm-9,8a1,1,0,0,0-1,1v1a1,1,0,0,0,2,0V20A1,1,0,0,0,12,19ZM18.36,17A1,1,0,0,0,17,18.36l.71.71a1,1,0,0,0,1.41,0,1,1,0,0,0,0-1.41ZM12,6.5A5.5,5.5,0,1,0,17.5,12,5.51,5.51,0,0,0,12,6.5Zm0,9A3.5,3.5,0,1,1,15.5,12,3.5,3.5,0,0,1,12,15.5Z" /></svg>
                            <svg className="swap-off fill-current w-10 h-10" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21.64,13a1,1,0,0,0-1.05-.14,8.05,8.05,0,0,1-3.37.73A8.15,8.15,0,0,1,9.08,5.49a8.59,8.59,0,0,1,.25-2A1,1,0,0,0,8,2.36,10.14,10.14,0,1,0,22,14.05,1,1,0,0,0,21.64,13Zm-9.5,6.69A8.14,8.14,0,0,1,7.08,5.22v.27A10.15,10.15,0,0,0,17.22,15.63a9.79,9.79,0,0,0,2.1-.22A8.11,8.11,0,0,1,12.14,19.73Z" /></svg>
                        </label>
                    </div>
                </div>
            </div>
        </>
    );
};

export default NavBar;